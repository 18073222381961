import { RootState } from './rootReducer';

export const actions = {
  notifications: {
    RESET: 'NOTIFICATION_RESET',
  },
  errors: {
    RESET: 'ERRORS_RESET',
  },
  action: {
    DISPATCH_ACTION: 'DISPATCH_ACTION',
    EXECUTE_ACTION: 'EXECUTE_ACTION',
    GET_INSTRUMENT_BEHAVIOUR_ACTIONS: 'GET_INSTRUMENT_BEHAVIOUR_ACTIONS',
  },
};

// Handling for action form override object
export const ACTION_FORM_OVERRIDE_ACTIONS = {
  SET_ACTION_OVERIDE_OBJECT: 'SET_ACTION_OVERIDE_OBJECT',
  RESET_ACTION_OVERIDE_OBJECT: 'RESET_ACTION_OVERIDE_OBJECT',
};

export type ActionOverrideObjInitialState = {
  [key: string]: any;
};

export type ActionFormActionType = {
  type: string;
  payload: any;
};

export const actionFormOverride = (state: ActionOverrideObjInitialState = {}, action: ActionFormActionType) => {
  switch (action.type) {
    case ACTION_FORM_OVERRIDE_ACTIONS.SET_ACTION_OVERIDE_OBJECT:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_FORM_OVERRIDE_ACTIONS.RESET_ACTION_OVERIDE_OBJECT:
      return {
        ...{},
      };
    default:
      return state;
  }
};

export const actionFormOverrideSelector = (state: RootState) => state.actionFormOverride || {};
