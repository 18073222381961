import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
import { sanitizeGridData } from 'src/mappers/orders/sanitize-order-data';
import { addDefaultLayoutIfNotDefined, addViewNameToLayoutData } from 'src/mappers/layouts/layouts.mapper';
import { ViewUris } from 'src/utils/constants';
import { mapFieldRules } from 'src/api/field-rules/field-rules.mapper';
import { Behaviour } from 'src/pages/Portfolio/views/portfolio-blotter.model';

export interface SearchFundsProps {
  fundURIs: string[];
  type: string;
}

/*
This is a temporary solution as API to fetch position accept GET request with multiple fund in params.
TODO: pass array of fundURIs directly in body once API gets updated
*/
const getStringFundURI = (fundURIs: string[]) => fundURIs.map((value: string) => `${value}`).join('&fund=');

export const get = ({ fundURIs, type = 'portfolio_positions' }: SearchFundsProps) => ({
  url: `${endpoints.views.positions}?fund=${getStringFundURI(fundURIs)}`,
  transform: (body: any) => {
    const { data, schema, web_layouts, view_uri, client_view_configuration } = body;
    const webLayouts = web_layouts.length > 0 ? JSON.parse(web_layouts) : web_layouts;
    const transformed_data = sanitizeGridData(JSON.parse(data));
    transformed_data.forEach((data: any) => {
      if (data?.Behaviour?.toLowerCase().indexOf(Behaviour.LOAN) !== -1) {
        data.Quantity = '';
      }
    });
    return {
      views: {
        [`clientData_${type}`]: {
          data: transformed_data,
          schema,
          viewUri: view_uri,
          fieldRules:
            body.field_rules && typeof body.field_rules === 'string'
              ? mapFieldRules(JSON.parse(body.field_rules))
              : body.field_rules,
          webLayouts: addViewNameToLayoutData(addDefaultLayoutIfNotDefined(webLayouts), ViewUris.rcmPortfolioDashboard),
          client_view_configuration: client_view_configuration ? JSON.parse(client_view_configuration)?.data : [{}],
        },
      },
    };
  },
  options: { method: 'GET' },
  queryKey: `getPositions_${type}`,
  force: true,
  update: {
    views: (prev: any, next: any) => ({
      ...prev,
      ...next,
    }),
  },
});

export const selector = (state: RootState, type: string) => state.entities.views?.[`clientData_${type}`];

export const isPending = (state: RootState, type: string) =>
  state.queries[`getPositions_${type}`] && state.queries[`getPositions_${type}`].isPending;

export const selectedFundURIs = (state: RootState, type: string) =>
  state.portfolios.selectedFundURIs[type]?.map((item: { uri: string; display_name: any }) => item.uri);

export const selectedFunds = (state: RootState, type: string) => state.portfolios.selectedFundURIs[type];

export const createOrderInstrument = (body: { [key: string]: any }) => ({
  url: `${endpoints.order.newOrderInstrument}`,
  options: { method: 'POST' },
  queryKey: `createNewOrderInstrument`,
  body,
  force: true,
  update: {
    newOrderInstrument: (_: any, next: any) => next,
  },
});

export const newOrderselector = (state: RootState) => state.entities.newOrderInstrument;

export const newOrderIsPending = (state: RootState) =>
  state.queries.createNewOrderInstrument && state.queries.createNewOrderInstrument.isPending;
