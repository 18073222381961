import { RootState } from 'src/reducers/rootReducer';
import { GlideAPIFieldRules } from 'src/api/field-rules/field-rules.model';
import { getDisplayViewGroup, displayViewGroupSelector, GetDisplayViewType, isPending } from '../display-view';

export type OrderDisplayViewAPIBodyType = {
  data: string;
  display_name: string;
  uri: string;
  display_view: string;
  workflow_transitions?: string;
  workflow_status_history?: string;
  field_rules: GlideAPIFieldRules;
};

export const generateOrderDisplayViewData = (body: OrderDisplayViewAPIBodyType) => {
  return {
    data: {
      ...(body.data ? JSON.parse(body.data) : {}),
      workflow_status_history: body.workflow_status_history ? JSON.parse(body.workflow_status_history) : [],
      workflow_transitions: body.workflow_transitions ? JSON.parse(body.workflow_transitions) : [],
    },
    fieldRules: body.field_rules,
    uri: body.uri,
    displayView: JSON.parse(body.display_view),
    displayName: body.display_name,
  };
};

export const get = ({ uri, fetchOptions, activeView }: GetDisplayViewType) =>
  getDisplayViewGroup({
    viewName: activeView,
    uri,
    fetch_options: fetchOptions,
    transformFn: generateOrderDisplayViewData,
  });

export const selector = (state: RootState, activeView: string) => displayViewGroupSelector(state, activeView);

export const pending = (state: RootState, activeView: string) => isPending(state, activeView);
