import { ReactNode } from 'react';
import { MarketmapRealTimeInfo } from 'src/models/marketmap/marketmap.model';
import { MarketmapFields } from 'src/utils/constants';
import { DxGridSelectionProps } from 'src/components/grids/dxgrid-selection';
import { fieldRules } from 'src/utils/custom-field-rules';
export interface ReduxProps {
  readonly positions: any;
  readonly isLoading: boolean;
  readonly fundURIs: string;
}

export interface ReduxDispatch {
  getPortfolioHoldings: (fundURI: string) => void;
}

export interface CreditBlotterViewProps
  extends Omit<
    DxGridSelectionProps,
    | 'id'
    | 'headerText'
    | 'dataSource'
    | 'layouts'
    | 'onCreateNewLayout'
    | 'selectedLayout'
    | 'onSaveLayout'
    | 'onRowClick'
    | 'glideSession'
  > {
  onSettingsClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loadPanelEnabled: any;
  setShowDxPanel: any;
  modalTemplate: ReactNode | null;
  modalOrderTemplate: ReactNode | null;
  onRefreshCallback?: () => void;
}

export enum MarketMapColumns {
  'id' = 'id',
  'MarketMapID' = 'Market Map ID',
  'LastPrice' = 'Last Price',
}

export enum Behaviour {
  BOND = 'bond',
  ABS = 'abs',
  LOAN = 'loan',
}
export const DataGridColumn: { [key: string]: any } = {
  position_amount_last: {
    display_name: 'Position Amount Last',
    dataField: 'Position Amount Last',
    data_type: 'Decimal',
    key: 'position_amount_last',
    calculation_script_js: fieldRules['calculatePositionLastAmount'],
    aggregation_calculation: 'Sum',
  },
  'Last Price': {
    field_name: 'market_map_last_price',
  },
  MTM: {
    field_name: 'mtm',
  },
};
export const portfolioRealTimeConfig: MarketmapRealTimeInfo = {
  id: MarketMapColumns.id,
  columnToUse: MarketMapColumns.MarketMapID,
  columnToUpdate: MarketMapColumns.LastPrice,
  marketmapFields: [
    MarketmapFields.LAST_PRICE,
    MarketmapFields.SYMBOL,
    MarketmapFields.INSTRUMENT_NAME,
    MarketmapFields.NET_CHANGE,
    MarketmapFields.PERC_NET_CHANGE,
  ],
};
export type Props = CreditBlotterViewProps & ReduxProps & ReduxDispatch;
