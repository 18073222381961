import { Location as ReachLocation, LocationContext, Router } from '@reach/router';
import { appsLinks } from '@virtus/common/appLinks/appLinks';
import getEnvFromUrl from '@virtus/common/utils/getEnvFromUrl';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { AppLink, AppNames } from '@virtus/components/NavigationBar/components/AppMenu/AppMenu';
import React, { Suspense, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import * as S from 'src/app/App/App.style';
import { VirtusTheme } from '@virtus/components';
import AuthenticationHandlerGlide from 'src/app/App/AuthenticationHandlerGlide';
import { TopRightMenuItemProps } from '@virtus/components/NavigationBar/components/NavigationBarMenu/NavigationBarMenu';
import TabPanelNavigation from 'src/components/tab-panel-navigation/tab-panel-navigation';
import config, { GlideAppConfig } from 'src/config';
import configureHeader from 'src/config/headerConfig';
import routes from '../routes/routes';
import { useEventListener } from 'src/hooks/useEventListener';
import { CustomGlideRoute, TabsAction, tabSelector, TabState } from 'src/reducers/tabs';
import { DbSelectorOverlay } from 'src/components/db-selector-overlay/db-selector-overlay';
import startCase from 'lodash/startCase';
import { RootState } from 'src/reducers';
import { Dispatch } from 'redux';
import './App.css';
import Notifications from 'src/components/page-wrapper/components/Notifications/Notifications';
import { notificationSelector } from 'src/reducers/notifications';
const DashboardPage = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/dashboard-page/dashboard-page'));
const Assets = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Assets/Assets'));
const Header = React.lazy(() => import('@virtus/components/page/Header'));
const Orders = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Orders/Orders'));
const Hypos = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Hypos/Hypos'));
const Credits = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Credits/Credits'));
const Portfolio = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Portfolio/Portfolio'));
const PortfolioAnalyzer = React.lazy(
  () => import(/* webpackPreload: true */ 'src/pages/portfolio-analyzer/portfolio-analyzer'),
);
const Compliance = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Compliance/Compliance'));
const Deals = React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/Deals/Deals'));

interface ReduxProps {
  getUsername: () => string;
  glideSession: GlideSession;
  tabs: TabState;
  notification: any;
}

interface ReduxDispatch {
  openNewTab: (path: string) => void;
  closeTab: (data: CustomGlideRoute) => void;
}
interface OwnProps {
  config: GlideAppConfig;
}

type AppProps = ReduxProps & OwnProps & ReduxDispatch;

const getAppRoutes = () => {
  if (config.routePathsDisabled) {
    return routes.filter(appRoute => !config.routePathsDisabled?.includes(appRoute.path));
  }
  return routes;
};

const headerConfiguration = configureHeader(getAppRoutes());

const envValidator = (env: string[], location: string, glideSession?: GlideSession): boolean => {
  return getEnvFromUrl(env, location) && glideSession?.user?.roles.some((role: string) => role.includes('admin'));
};

interface TopRightMenuItems {
  validator: boolean;
  items: TopRightMenuItemProps;
}

const getTopRightMenuItems = (itemsGroup: TopRightMenuItems[]): TopRightMenuItemProps[] => {
  return itemsGroup
    .filter(itemGroup => itemGroup.validator)
    .map(group => {
      return group.items;
    });
};

// Needed to be able to target Glide website from other portal apps
window.name = 'glide';

/**
 * This is to disable alert message from Muse lib.
 * @Note :- This will also override any javascript alert message in entire Glide application
 */
window.alert = (msg: string) => console.warn(`[Muse] ${msg}`);

const appMenuLinks: AppLink[] = [
  {
    name: AppNames.nexus,
    // @ts-ignore
    route: appsLinks.nexus[__ENVIRONMENT__],
    color: 'var(--fis-green)',
  },
  {
    name: AppNames.glide,
    // @ts-ignore
    route: appsLinks.glide[__ENVIRONMENT__],
    color: 'var(--default-blue)',
  },
  {
    name: AppNames.genesis,
    // @ts-ignore
    route: appsLinks.genesis[__ENVIRONMENT__],
    color: 'var(--purple-genesis)',
  },
];

/** Theme, Header and Routing */
const App: React.FC<AppProps> = ({ getUsername, glideSession, openNewTab, closeTab, tabs, notification }) => {
  const [showDBDialog, setShowDBDialog] = useState(false);
  const handleOnCloseDBDialog = () => setShowDBDialog(false);
  let misc: JSX.Element | string;
  const isAdminOnDevEnvironment = envValidator(['local', 'dev'], location.href, glideSession);
  const backHandler = () => {
    closeTab({
      fullPath: tabs.currentPath,
      isHighlighted: true,
      activeView: '',
    });
  };

  useEventListener('popstate', backHandler, window);

  const keyHandler = (event: KeyboardEvent) => {
    const winShortcut = event.altKey && event.key === '?';
    const osxShortcut = event.metaKey && event.key === '/';
    if (winShortcut || osxShortcut) return setShowDBDialog(true);
  };

  useEventListener('keydown', keyHandler);

  const clientSelector = useCallback(() => {
    if (getEnvFromUrl(['dev', 'local'], location.href) && glideSession) {
      return (
        <DbSelectorOverlay
          show={showDBDialog}
          onClose={handleOnCloseDBDialog}
          currentEnv={localStorage.getItem('client_env') || ''}
        />
      );
    }
    return null;
  }, [glideSession, showDBDialog]);

  const changeRoute = (path: string) => {
    openNewTab(path);
  };

  return (
    <Suspense fallback={<div />}>
      <VirtusTheme>
        <AuthenticationHandlerGlide config={config} setClientEnv={setShowDBDialog}>
          {({ isGlideAuthenticated, login, logout }) => (
            <S.StyledApp>
              <ReachLocation>
                {(location: LocationContext) => {
                  const topRightMenuItems = getTopRightMenuItems([
                    {
                      validator: true,
                      items: {
                        text: 'Logout',
                        onClick: () => {
                          logout(config);
                        },
                      },
                    },
                  ]);
                  if (isAdminOnDevEnvironment) {
                    misc = (
                      <S.Button onClick={() => setShowDBDialog(true)}>
                        {startCase(localStorage.getItem('client_env') || 'Demo')}
                      </S.Button>
                    );
                  }
                  return (
                    isGlideAuthenticated && (
                      <>
                        <Header
                          {...headerConfiguration}
                          appMenuLinks={appMenuLinks}
                          appMenuValue="Glide"
                          isLoggedIn={isGlideAuthenticated}
                          topRightButtonText={getUsername()}
                          path={location.location.pathname}
                          topRightMenuItems={topRightMenuItems}
                          onLogin={login}
                          enabledApps={[AppNames.nexus, AppNames.glide]}
                          appColor="var(--default-blue)"
                          misc={misc}
                          routeChangeCallback={changeRoute}
                          isHelpVisible={true}
                          helpURL={config.glide.baseURL + '/glide/help'}
                        />
                        <TabPanelNavigation />
                        <Router>
                          <Assets path="/universe-assets" />
                          <DashboardPage default path="/" />
                          <Orders path="/orders" />
                          <Hypos path="/hypos" />
                          <Credits path="/credit" />
                          <Portfolio path="/portfolio" />
                          <PortfolioAnalyzer path="/portfolio-analyzer" />
                          <Deals path="/deals" />
                          <Compliance path="/compliance" />
                        </Router>
                      </>
                    )
                  );
                }}
              </ReachLocation>
              {notification && !notification?.showMessagesOnPopup && <Notifications />}
            </S.StyledApp>
          )}
        </AuthenticationHandlerGlide>
        {clientSelector()}
      </VirtusTheme>
    </Suspense>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  getUsername: () => authSelectors.getUsername(state),
  glideSession: authSelectors.glideSession(state),
  notification: notificationSelector(state),
  tabs: tabSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  openNewTab: (path: string) => dispatch({ type: TabsAction.OPEN_TAB, path }),
  closeTab: (data: CustomGlideRoute) => dispatch({ type: TabsAction.CLOSE_TAB, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
