/* tslint:disable:max-line-length */
/**
 * Toggle Components visibility and props to components
 *
 * THIS IS MAINLY TO FACILITATE COMPONENT OPERATIONS (SHOW/HIDE COMPONENTS) BETWEEN DIFFERENT PAGE
 * example usage found with:
 *  - searchInspector (for toggling visibility between page inspector and search inspector)
 *  - contextMenu (saga)
 *  - actionForm (saga)
 *
 * NOTES:
 *  - IT IS NOT A REPLACEMENT FOR PAGE INTERNAL REDUCERS. page reducers are much faster.
 *  - USING COMPONENT STATES IN THE DXDATGRID LISTENERS CAN CREATE DEAD LOCKS!
 * There should be no need for the grid to interact with those states
 */
import { RootState } from 'src/reducers/rootReducer';

// New components need to be registered
export type Component =
  | 'searchInspector'
  | 'contextMenu'
  | 'actionForm'
  | 'columnManagerInspector'
  | 'creditDetailsInspector'
  | 'dealsInspector'
  | 'datePicker'
  | 'summaryPanel'
  | 'showLayoutManagerInspector'
  | 'dashboard'
  | 'dealsDetailInspector'
  | 'issuerDetailsInspector'
  | 'bottomPanelExpandedState'
  | 'livePriceToggleState';

export type ComponentPropsType = {
  [key in Component]: any;
};

export interface ComponentsDispatch {
  // Set component visibility dynamically
  toggleComponentVisibility: (component: Component, visible: boolean) => void;
  toggleComponent: (component: Component, props: object) => void;
}

export interface ComponentProps {
  readonly components: ComponentPropsType;
}

export interface ComponentPayload {
  component: Component;
  props: {
    visible: boolean;
    [key: string]: any;
  };
}

export enum ComponentActionTypes {
  // Generic actions applies to all components
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  TOGGLE_DISPLAY_COMPONENT = 'TOGGLE_DISPLAY_COMPONENT',
  RESET = 'RESET',
  // Specific actions set multiple component visibility
  TOGGLE_SEARCH_INSPECTOR = 'TOGGLE_SEARCH_INSPECTOR',
}

export interface LivePriceViewProps {
  reqCountId: number;
  isMarketmapConnectedToServer: boolean;
  isLoggedInToMarketmap: boolean;
  marketMapToggleStatus: any;
}
const initialState: ComponentPropsType = {
  actionForm: { visible: false },
  searchInspector: { visible: false },
  creditDetailsInspector: { visible: false, tabs: [] },
  dealsInspector: { visible: false },
  contextMenu: { visible: false },
  columnManagerInspector: {
    visible: false,
    props: {},
  },
  datePicker: {
    visible: false,
    count: 0,
    date: [],
    dateConfig: {
      singleDate: '',
      toDate: '',
      fromDate: '',
      frequency: '',
      dateType: 'single',
    },
  },
  summaryPanel: { visible: false },
  showLayoutManagerInspector: { visible: false },
  dashboard: { dashboardId: '' },
  dealsDetailInspector: { visible: false },
  issuerDetailsInspector: { visible: false },
  bottomPanelExpandedState: { isExpanded: false },
  livePriceToggleState: {
    reqCountId: 0,
    isMarketmapConnectedToServer: false,
    isLoggedInToMarketmap: false,
    marketMapToggleStatus: {},
  },
};

export const components = (
  state: ComponentPropsType = initialState,
  action: { type: ComponentActionTypes; payload: ComponentPayload },
) => {
  switch (action.type) {
    case ComponentActionTypes.TOGGLE_DISPLAY_COMPONENT:
      return { ...state, [action.payload.component]: { ...state[action.payload.component], ...action.payload.props } };
    case ComponentActionTypes.OPEN:
      return { ...state, [action.payload.component]: { ...state[action.payload.component], visible: true } };
    case ComponentActionTypes.CLOSE:
      return { ...state, [action.payload.component]: { ...state[action.payload.component], visible: false } };
    default:
      return state;
  }
};

export const actionFormSelector = (state: RootState) => state.components.actionForm;
export const componentSelector = (state: RootState) => state.components;
