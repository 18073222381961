import capitalize from 'lodash/capitalize';

export const fixUserName = (dataCellValue: string = '') => {
  if (!dataCellValue || typeof dataCellValue !== 'string') return '';
  return dataCellValue
    .replace('users/', '')
    .replace('_', ' ')
    .split(' ')
    .map((word: string) => capitalize(word))
    .join(' ');
};

export const fixGlideId = (glideId: string = '') => (isNaN(Number(glideId)) ? glideId : Number(glideId));
export const fixCounterparty = (counterparty: string = '') => counterparty.replace('instance/counterparty/', '');

export const sanitizeGridData = (data: any) =>
  data.map((value: any, index: number) => {
    if (value._uri && !value['Glide ID']) {
      value['Glide ID'] = fixGlideId(value._uri?.lastSplitValue());
    } else if (value['Glide ID']) {
      value['Glide ID'] = fixGlideId(value['Glide ID'].toString().lastSplitValue());
    }
    if (value.Counterparty) value.Counterparty = fixCounterparty(value.Counterparty);
    if (value.Analyst) value.Analyst = fixUserName(value.Analyst);
    if (value['Created By']) value['Created By'] = fixUserName(value['Created By']);
    if (value['Order PM']) value['Order PM'] = fixUserName(value['Order PM']);
    if (value['RCM Trader']) value['RCM Trader'] = fixUserName(value['RCM Trader']);
    return { ...value, ...{ id: index } };
  });
