import { requestAsync, updateEntities } from 'redux-query';
import { putResolve, select, takeLatest } from 'redux-saga/effects';
import { OrderDisplayView } from 'src/api/queries';

interface ActionPayload {
  payload: { orderUri: string; issuerName: string; activeViewKey: string };
}

export enum OrdersSagaAction {
  FETCH_ORDER_DISPLAY_VIEW = 'FETCH_ORDER_DISPLAY_VIEW',
}

export function* getOrderDisplayView(action: ActionPayload) {
  const orderUri = action.payload?.orderUri;
  const issuerName = action.payload?.issuerName;
  const activeView = action.payload?.activeViewKey;
  const orderArguments = yield select(OrderDisplayView.selector, activeView);
  if (!orderArguments || orderArguments.uri !== orderUri) {
    yield putResolve(
      updateEntities({
        views: (prev: any, _: any) => ({
          ...prev,
          [`clientData_${activeView}`]: {
            ...prev[`clientData_${activeView}`],
            inspectorData: { ...orderArguments, displayName: issuerName, uri: orderUri },
          },
        }),
      }),
    );
    yield putResolve(
      requestAsync(OrderDisplayView.get({ uri: orderUri, activeView, fetchOptions: 'workflow_transitions' })),
    );
  }
}

function* watchFetchOrderDisplayView() {
  yield takeLatest<any>(OrdersSagaAction.FETCH_ORDER_DISPLAY_VIEW, getOrderDisplayView);
}

export default [watchFetchOrderDisplayView];
