import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
export const get = (hypo_scenario_uri: string) => ({
  url: endpoints.hypo.testResults,
  transform: (body: any) => ({ hypoScenarioTestResults: { data: JSON.parse(body.data), schema: body.schema } }),
  options: { method: 'GET' },
  body: { hypo_scenario_uri },
  queryKey: 'getHypoScenarioTestResults',
  force: true,
  update: { hypoScenarioTestResults: (_: any, next: any) => next },
});

export const selector = (state: RootState) => state.entities.hypoScenarioTestResults;
export const pending = (state: RootState) =>
  state.queries.getHypoScenarioTestResults && state.queries.getHypoScenarioTestResults.isPending;
