import { MappedFormElementsObject, GlideDisplayView, ViewElement } from 'src/models/api/viewElement';
import { getFormElementType, getOptions } from 'src/mappers/action-arguments/action-entity';
import SearchService from 'src/services/search.service';
import { FormFieldOptions } from 'src/components/forms/form-elements/FormElement.model';
import { GlideObject } from 'src/models/glide/glideObject';
import { FieldRules } from 'src/api/field-rules/field-rules.model';
import { WorkflowTransitionElement } from 'src/models/order/glideOrderDisplayView.api.model';
import { formatDate } from '@virtus/common/utils/formatters';

export enum GlideFormDataTypes {
  DateTime = 'datetime',
  Object = 'object',
  ObjectCollection = 'objectcollection',
  String = 'string',
  LargeString = 'largestring',
  Decimal = 'decimal',
  Bit = 'bit',
}

export interface GetFieldValue {
  dataType: string;
  defaultValue: string | number | string[] | null | boolean | undefined;
  isARequiredObject?: boolean;
  options?: FormFieldOptions;
}

export const getFormFieldValue = ({ dataType, defaultValue }: GetFieldValue) => {
  switch (dataType as GlideFormDataTypes) {
    case GlideFormDataTypes.ObjectCollection:
      return defaultValue;
    case GlideFormDataTypes.Bit:
      return Boolean(defaultValue);
    case GlideFormDataTypes.Decimal:
      return defaultValue || defaultValue === 0 ? String(defaultValue) : '';
    case GlideFormDataTypes.DateTime:
      // TODO: Remove this block once core api handles null value date
      // eslint-disable-next-line no-case-declarations
      let value = defaultValue;
      if (defaultValue === '{today}') {
        value = formatDate(new Date().toISOString(), 'dd/MM/yyyy');
      }
      return value;
    default:
      return defaultValue ? String(defaultValue) : '';
  }
};
export const isFieldDisabled = (field: ViewElement) => {
  const visibilityStatus = () => {
    return hasDefaultVisibleStyleDefined(field)
      ? !field.style!['instance/display_styles/defaultvisible'].is_editable
      : false;
  };

  return hasDefaultReadonlyStyleDefined(field)
    ? !field.style!['instance/display_styles/defaultreadonly'].is_editable
    : visibilityStatus();
};
const hasDefaultVisibleStyleDefined = (field: ViewElement) =>
  field.style && field.style['instance/display_styles/defaultvisible'];

const hasDefaultReadonlyStyleDefined = (field: ViewElement) =>
  field.style && field.style['instance/display_styles/defaultreadonly'];

export const isFieldReadonly = (field: ViewElement) => {
  return hasDefaultReadonlyStyleDefined(field)
    ? !field.style!['instance/display_styles/defaultreadonly'].is_editable
    : field.summary === 'true';
};
export const getFieldsFromView = (
  entityInfo: ViewElement[],
  allFieldRules: FieldRules = {},
  customActionOVerride: any = {},
) => {
  if (!entityInfo) return {};
  return entityInfo.reduce((acc: any, field: ViewElement) => {
    if (typeof field.field === 'string') return acc;
    const instance_uri = Object.keys(field.field)[0];
    const fieldName = instance_uri.replace('fields/', '');
    const firstField = Object.values(field.field)[0];
    const dataType = firstField.data_type.replace('lookups/', '');
    const object_type = firstField.object_type || firstField.object_type_;
    const searchType = object_type ? object_type.replace('object_types/', '') : '';
    const name = firstField.display_name;
    const defaultValue = customActionOVerride?.[instance_uri]?.defaultValue || field.value || field?.default_value;
    const renderType = field.render_type ?? null;
    let format = '';
    if (firstField.hasOwnProperty('format')) {
      format = firstField.format.uri
        ? firstField.format.uri.replace('lookups/', '')
        : firstField.format.replace('lookups/', '');
    }
    let action = '';
    if (field.hasOwnProperty('object_type') && Object.values(field?.object_type)[0]) {
      const objectTypeDetails: any = Object.values(field.object_type)[0];
      action = objectTypeDetails.actions ? objectTypeDetails.actions.find((actn: string) => actn.includes('new')) : '';
    }
    const readonly = isFieldReadonly(field);
    const disabled = isFieldDisabled(field);

    const fieldRules = allFieldRules?.[instance_uri] ?? null;
    const clientRegion = field.hasOwnProperty('client_region') ? field.client_region : null;
    return {
      ...acc,
      ...{
        [fieldName]: {
          name,
          displayName: name,
          dataType,
          defaultValue: getFormFieldValue({ dataType, defaultValue }),
          formElementType: getFormElementType({
            dataType,
            fieldName: firstField.display_name,
            hasLookUps: customActionOVerride?.[instance_uri]?.lookups || firstField?.lookups,
          }),
          required: Boolean(field.required_field) && !readonly,
          readonly,
          disabled,
          format,
          searchType,
          searchService: SearchService().search,
          options: customActionOVerride?.[instance_uri]?.lookups || getOptions(firstField.lookups),
          client_region: clientRegion,
          renderType,
          fieldRules,
          action,
        },
      },
    };
  }, {});
};

/**
 * For story 59842
 * At present we have different mappers for order/credit details.
 * This mapper function is similar to credit details mapper and using it for Asset/Instrument
 * @todo Accommodate logic of field rules/workflow/workflowActions as well so that it can be reused
 * @todo test any impact on existing functionality
 */
export const mapDetailsToFormElements = (displayViewData: GlideDisplayView) => {
  const formGroups = Object.entries(displayViewData).reduce(
    (acc, [groupName, values]) => ({ ...acc, [groupName]: getFieldsFromView(values) }),
    {},
  );

  const mappedObject: MappedFormElementsObject = {
    formGroups,
  };

  return mappedObject;
};

export const extractfieldsFromDisplayView = (capitalDisplayViewData: any) => {
  const fields = capitalDisplayViewData.field_display_definition.map((fdd_url_data: GlideObject) => {
    return {
      name: fdd_url_data?.data?.field.replace('fields/', ''),
      display_name: fdd_url_data?.data?.display_name.replace(/(FDD)*( )*(:)/gi, ''),
    };
  });

  const datesToExtract = capitalDisplayViewData.default_dates.replace(/({|}|,>q)/gi, '').split(',');
  const quarter = Math.floor(new Date().getMonth() / 3);

  const dates = datesToExtract.map((dt: string) => {
    if (dt.trim() === 'today-1q') {
      return new Date(new Date().getFullYear(), quarter * 3, 2).toISOString();
    } else if (dt.trim() === 'today-2q') {
      return new Date(new Date().getFullYear(), quarter * 3 - 3, 2).toISOString();
    } else if (dt.trim() === 'today-3q') {
      return new Date(new Date().getFullYear(), quarter * 3 - 6, 2).toISOString();
    } else {
      return new Date().toISOString();
    }
  });
  const render_type = capitalDisplayViewData.render_type;

  return {
    fields,
    dates,
    render_type,
    client_region: capitalDisplayViewData.client_region.split(',')[1],
  };
};

export const parseWorkflowTransitions = (workflowTransitions: WorkflowTransitionElement[]) => {
  const result: any = {};
  if (!workflowTransitions) return result;
  workflowTransitions.forEach((workflowAction: WorkflowTransitionElement | any) => {
    const displayName = workflowAction?.display_name || workflowAction?.data?.display_name;
    result[displayName] = {
      displayName: displayName,
      uri: workflowAction.uri,
    };
  });
  return result;
};
