import { put, putResolve, takeLatest } from 'redux-saga/effects';
import { Glide, PortfolioBlotter, SearchQuery } from 'src/api/queries';
import { QueryConfig, requestAsync } from 'redux-query';
import { PortfolioActionTypes } from 'src/pages/Portfolio/views/portfolio.reducer';
import { TabsAction } from 'src/reducers/tabs';
import { NotificationsAction, GlideNotificationTypes } from 'src/reducers/notifications';

export enum PortfolioSagaAction {
  FETCH_PORTFOLIO_NAMES = 'FETCH_PORTFOLIO_NAMES',
  FETCH_PORTFOLIO_HOLDINGS = 'FETCH_PORTFOLIO_HOLDINGS',
  CREATE_NEW_ORDER_INSTRUMENT = 'CREATE_NEW_ORDER_INSTRUMENT',
}

export function* fetchPortfolioNames() {
  const assets = yield putResolve(requestAsync(SearchQuery.get({ assetName: null, assetType: 'funds', cache: false })));
  const fundsUri = assets && assets.entities.searchAssets.data.map((data: any) => data.uri);
  yield putResolve(requestAsync(Glide.post({ uri: fundsUri, cache: false, reducerPropName: 'portfolios' })));
}

export function* fetchPortfolioHoldings(action: any) {
  // tab name change
  yield put({
    type: TabsAction.MODIFY_TAB_NAME,
    data: { fullPath: action.payload.fullPath, tabName: action.payload.tabName },
  });
  // store fund URI to enable refresh
  yield put({
    type: PortfolioActionTypes.STORE_SELECTED_PORTFOLIOS,
    payload: { selectedFundURIs: action.payload.fundURIs, type: action.payload.type },
  });
  // get portfolio holdings
  yield putResolve(
    requestAsync(
      PortfolioBlotter.get({
        fundURIs: action.payload.fundURIs.map((item: { uri: string; data: any }) => item.uri),
        type: action.payload.type,
      }) as QueryConfig,
    ),
  );
}

export function* resetStoredPortfolios(action: any) {
  // reset fund URIs on tab close
  if (action.data.fullPath.includes('portfolio')) {
    yield put({
      type: PortfolioActionTypes.RESET_STORED_PORTFOLIO,
    });
  }
}

export function* createNewOrderInstrument(action: any) {
  yield put({
    type: NotificationsAction.PENDING_NOTIFICATION,
  });
  const orderData = yield putResolve(
    requestAsync(PortfolioBlotter.createOrderInstrument({ ...action?.payload }) as QueryConfig),
  );

  if (orderData && orderData?.body) {
    yield put({
      type: NotificationsAction.ACTION_RESOLVED_NOTIFICATION,
      payload: {
        resolved_entity_type: GlideNotificationTypes.create_new_order_with_instrument_behaviour,
        resolved_entity_uri: orderData?.body?.uri[0],
      },
    });
  }
}

export function* watchFetchPortfolio() {
  yield takeLatest(PortfolioSagaAction.FETCH_PORTFOLIO_NAMES, fetchPortfolioNames);
}

export function* watchFetchPortfolioHoldings() {
  yield takeLatest(PortfolioSagaAction.FETCH_PORTFOLIO_HOLDINGS, fetchPortfolioHoldings);
}

export function* watchClosePortfolioTabs() {
  yield takeLatest(TabsAction.CLOSE_TAB, resetStoredPortfolios);
}

export function* watchCreateNewOrderPortfolio() {
  yield takeLatest(PortfolioSagaAction.CREATE_NEW_ORDER_INSTRUMENT, createNewOrderInstrument);
}
export default [
  watchFetchPortfolio,
  watchFetchPortfolioHoldings,
  watchClosePortfolioTabs,
  watchCreateNewOrderPortfolio,
];
