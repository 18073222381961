import { requestAsync } from 'redux-query';
import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { InstrumentBehaviourActions } from 'src/api/queries';
import { actions } from 'src/reducers/actions';
import { ComponentActionTypes } from 'src/reducers/components';

export interface InstrumentBehaviourAction {
  instrumentBehaviourUri: string;
  target: any;
}

export interface InstrumentBehaviourActionPayload {
  payload: InstrumentBehaviourAction;
}

export function* getInstrumentBehaviourActions(action: InstrumentBehaviourActionPayload) {
  const { instrumentBehaviourUri, target } = action.payload;
  const instrumentBehaviourActions = yield select(InstrumentBehaviourActions.selector);
  if (!instrumentBehaviourActions || !instrumentBehaviourActions.hasOwnProperty(instrumentBehaviourUri)) {
    yield putResolve(requestAsync(InstrumentBehaviourActions.get(instrumentBehaviourUri)));
  }

  yield put({
    type: ComponentActionTypes.TOGGLE_DISPLAY_COMPONENT,
    payload: { component: 'contextMenu', props: { visible: true, instrumentBehaviourUri, target } },
    meta: { source: 'SAGA_INSTRUMENT_BEHAVIOUR_ACTION' },
  });
}

function* watchGetInstrumentBehaviourActions() {
  yield takeLatest<any>(actions.action.GET_INSTRUMENT_BEHAVIOUR_ACTIONS, getInstrumentBehaviourActions);
}

export default [watchGetInstrumentBehaviourActions];
