import NetworkInterface from '@virtus/common/auth/NetworkInterface';
import { MockRoute } from '@virtus/common/utils/loadMockData';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { queryMiddleware } from 'redux-query';
import createSagaMiddleware from 'redux-saga';
import config from 'src/config';
import rootReducer, { RootState } from 'src/reducers/rootReducer';
import { runAllSagas } from 'src/sagas/rootSaga';
import { NetworkInterface as ReduxQueryNetworkInteface } from 'redux-query';

export const getQueries = (state: RootState) => state.queries;
export const getEntities = (state: RootState) => state.entities;
export let store: Store;
/** setup saga and return store ready for <Provider /> */
export const initStore = (mockRoutes?: MockRoute): Store => {
  const sagaMiddleware = createSagaMiddleware();
  const portalNetworkInterface: NetworkInterface = new NetworkInterface({ ...config, mockRoutes });
  const reduxQueryMiddleware = queryMiddleware(
    portalNetworkInterface.reduxQueryNetworkInterface.bind(portalNetworkInterface) as ReduxQueryNetworkInteface,
    getQueries,
    getEntities,
  );
  const middleware = [reduxQueryMiddleware, sagaMiddleware];
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));
  runAllSagas(sagaMiddleware);
  return store;
};
