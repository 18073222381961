import { GlideIcon, VirtusIcon } from '@virtus/components/icons';
import { Route } from '@virtus/components/page/models/route';

export default (routes: Route[]) => ({
  routes: {
    name: 'root',
    mainLogo: {
      component: VirtusIcon,
      props: {
        style: {
          width: '100px',
        },
      },
    },
    path: '',
    subroutes: routes,
  },
  appButton: {
    name: 'Glide',
    icon: {
      component: GlideIcon,
      props: {
        style: { width: '12px', marginRight: '6px' },
      },
    },
    routePath: '/',
  },
});
