import { MockRoute } from '../utils/loadMockData';

export { getAccessToken } from './msalWrapper';
export { authActions } from './reducer';

export interface AppConfig {
  appInsightsKey?: string;
  b2c?: B2CConfig;
}

export interface GlideConfig {
  baseURL: string;
  mockRoutes?: MockRoute;
  API_WEB_SOCKET_URL?: string;
  environment?: string;
}

export interface IDPConfig {
  baseURL: string;
  firm: string;
  clientId: string;
  responseType: string;
  scope: string;
  clientEnvironment: string;
  serviceProviderId: string;
  idleTimeout: number;
}

export interface B2CConfig {
  appName: string;
  clientId: string;
  tenantId: string;
  b2cSigninPolicy?: any;
  userLoginApiUrl: string;
  b2cPasswordResetPolicy?: any;
  b2cProfileEditPolicy?: any;
  b2cProfileUrl?: any;
}

export interface GlideDashboardConfig {
  baseURL: string;
}

// Enhance B2C Configuration with additional fields derived from the base config parameters
export const createB2cConfig = (b2cConfig: Omit<B2CConfig, 'b2cProfileUrl'>) => {
  const { clientId, tenantId, b2cProfileEditPolicy } = b2cConfig;

  // eslint-disable-next-line max-len
  const b2cProfileUrl = `https://${tenantId}.b2clogin.com/${tenantId}.onmicrosoft.com/oauth2/v2.0/authorize?p=${b2cProfileEditPolicy}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
    `https://${location.host}`,
  )}&scope=openid&response_type=code&prompt=login&code_challenge=YTFjNjI1OWYzMzA3MTI4ZDY2Njg5M2RkNmVjNDE5YmEyZGRhOGYyM2IzNjdmZWFhMTQ1ODg3NDcxY2Nl`;
  return { ...b2cConfig, b2cProfileUrl };
};

export interface NexusConfig {
  registerURL: string;
  baseURL: string;
  signalrUrl: string;
  signalrClientId: string;
  signalrApiUrl: string;
  dataEnvironment: string;
  sessionTimeOut: number;
}

export interface PortalConfig extends AppConfig {
  glide: GlideConfig;
  b2c: B2CConfig;
  nexus: NexusConfig;
}
