import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
import { sanitizeGridData } from 'src/mappers/orders/sanitize-order-data';
import { addDefaultLayoutIfNotDefined, addViewNameToLayoutData } from 'src/mappers/layouts/layouts.mapper';
import 'src/app/App/App.prototype';

export const getClientView = (configUri: string) => {
  const configName = configUri.lastSplitValue();
  return {
    url: endpoints.clientViews.root,
    body: {
      uri: configUri,
    },
    transform: (body: any) => {
      const { data, schema, web_layouts, view_uri, client_view_configuration } = body;
      const webLayouts = web_layouts.length > 0 ? JSON.parse(web_layouts) : web_layouts;
      return {
        views: {
          [`clientData_${configName}`]: {
            data: sanitizeGridData(JSON.parse(data)),
            schema,
            viewUri: view_uri,
            clientViewConfiguration: JSON.parse(client_view_configuration).data,
            webLayouts: addViewNameToLayoutData(addDefaultLayoutIfNotDefined(webLayouts), view_uri.lastSplitValue()),
          },
        },
      };
    },
    options: { method: 'GET' },
    queryKey: `get_${configName}`,
    force: true,
    update: {
      views: (prev: any, next: any) => ({
        ...prev,
        [`clientData_${configName}`]: {
          inspectorData: prev?.[`clientData_${configName}`]?.inspectorData,
          collapseWrapperOpen: prev?.[`clientData_${configName}`]?.collapseWrapperOpen,
          ...next[`clientData_${configName}`],
        },
      }),
    },
  };
};

export const selector = (state: RootState, configUri: string) =>
  state.entities.views?.[`clientData_${configUri.lastSplitValue()}`];

export const isPending = (state: RootState, configUri: string) =>
  state.queries[`get_${configUri.lastSplitValue()}`] && state.queries[`get_${configUri.lastSplitValue()}`].isPending;
