import {
  ComplianceIcon,
  HyposIcon,
  PortfoliosIcon,
  Reports,
  TableIcon,
  LayoutsIcon,
  PivotTableIcon,
  CheckCircleOutlinedIcon,
} from '@virtus/components/icons';
import { Route } from '@virtus/components/page/models/route';
import React, { ComponentType } from 'react';
import { NavigationProps } from 'src/components/page-wrapper';

export interface GlideRoute extends Route {
  component: ComponentType<NavigationProps & any>;
  activeView?: string;
}

export const getRoute = (fullPath: string): GlideRoute => {
  const match = routes.filter((route: GlideRoute) => fullPath.split('?')[0] === route.fullPath);
  return match[0];
};

export const getDefaultView = (routepath: string) => {
  let activeView = '';
  routes.forEach((route: GlideRoute) => {
    if (route.activeView && route.fullPath === routepath) {
      activeView += `${route.activeView}`;
    }
  });
  return activeView;
};

export const routes: GlideRoute[] = [
  {
    icon: {
      component: Reports,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Assets',
    path: 'universe-assets',
    activeView: 'client_universe',
    fullPath: '/universe-assets',
    component: React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Assets/Assets')),
  },
  {
    icon: {
      component: ComplianceIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Orders',
    path: 'orders',
    activeView: 'order_blotter',
    fullPath: '/orders',
    component: React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Orders/Orders')),
  },
  {
    icon: {
      component: HyposIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Hypos',
    path: 'hypos',
    activeView: 'hypo_scenarios',
    fullPath: '/hypos',
    component: React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/Hypos/Hypos')),
  },
  {
    icon: {
      component: LayoutsIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Credit',
    path: 'credit',
    activeView: 'credit_dashboard',
    fullPath: '/credit',
    component: React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/Credits/Credits')),
  },
  {
    icon: {
      component: PortfoliosIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Portfolio',
    path: 'portfolio',
    fullPath: '/portfolio',
    component: React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/Portfolio/Portfolio')),
  },
  {
    icon: {
      component: PivotTableIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Portfolio Analyzer',
    path: 'portfolio-analyzer',
    fullPath: '/portfolio-analyzer',
    component: React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/portfolio-analyzer/portfolio-analyzer')),
  },
  {
    icon: {
      component: TableIcon,
      sideBarProps: { nativeColor: 'white', style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Deals',
    path: 'deals',
    fullPath: '/deals',
    activeView: 'deal_pipeline',
    displayName: 'Deal Pipeline',
    component: React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/Deals/Deals')),
  },
  {
    icon: {
      component: CheckCircleOutlinedIcon,
      sideBarProps: { nativeColor: 'white', style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Compliance',
    path: 'compliance',
    fullPath: '/compliance',
    activeView: 'compliance_blotter',
    displayName: 'Compliance',
    component: React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/Compliance/Compliance')),
  },
];

export default routes;
