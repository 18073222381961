import { ILayout } from 'src/components/grids/dxgrid-selection/templates/Layouts/Layouts.model';

interface UpdatedLayout {
  name: string;
  owner: string;
  json_layout: string;
}

export const DEFAULT_LAYOUT = {
  uri: 'default-layout',
  date: '',
  data: {
    name: 'Default',
    json_layout: {},
  },
};

export const addViewNameToLayoutData = (layouts: ILayout[], viewUri: string) =>
  layouts.map(layout => ({ ...layout, viewUri }));

export const addDefaultLayoutIfNotDefined = (webLayouts: ILayout[] = []) => {
  const layouts = Array.isArray(webLayouts) ? webLayouts : mapUpdatedLayoutsToILayout(webLayouts);
  const StoredDefaultLayout = layouts.find(layout => layout.data.name === DEFAULT_LAYOUT.data.name);
  return StoredDefaultLayout ? layouts : [...[DEFAULT_LAYOUT], ...layouts];
};

/* web_layouts received when save or update an existing layout */
const mapUpdatedLayoutsToILayout = (updatedLayouts: { [key: string]: UpdatedLayout }): ILayout[] =>
  Object.entries(updatedLayouts).map(([key, value]: [string, UpdatedLayout]) => ({
    data: {
      name: value.name,
      json_layout: value.json_layout,
    },
    owner: value.owner,
    date: '',
    uri: key,
  }));
