import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

interface QueryParams {
  uri: any;
  cache: boolean;
  reducerPropName: string;
}

interface GGQueryParams {
  uri: string;
  field: string;
  display_view?: boolean;
  field_display_format?: 'uri' | 'display_name' | 'summary' | 'lookups_display_name';
  expand_prop?: string;
  expand_fields_requested?: string[];
}

export const get = ({
  uri,
  field,
  field_display_format,
  display_view,
  expand_prop,
  expand_fields_requested,
}: GGQueryParams) => {
  return {
    url: `${endpoints.gget.root}`,
    body: { uri, field, field_display_format, display_view, expand_prop, expand_fields_requested },
    transform: (body: any) => {
      const [res] = body || [];
      if (!res) {
        return {
          field_uris: {
            [field]: '',
          },
        };
      }
      const { data = {} } = res;

      return {
        field_uris: {
          [field]: data[field] || '',
        },
      };
    },
    force: true,
    options: { method: 'GET' },
    params: {},
    queryKey: `gget_${field}`,
    update: {
      field_uris: (prev: any, next: any) => ({ ...prev, ...next }),
    },
  };
};

export const post = ({ uri, cache = true, reducerPropName }: QueryParams) => ({
  url: `${endpoints.gget.root}`,
  body: {
    uri,
  },
  transform: (body: any) => ({ [reducerPropName]: [...body] }),
  force: cache, // cache the request
  options: { method: 'POST' },
  params: {},
  queryKey: `${reducerPropName}=${uri.join('')}`,
  update: {
    [reducerPropName]: (_: any, next: any) => next,
  },
});

export const selector = (state: RootState, reducerPropName: string) => state.entities[reducerPropName];
export const ggselector = (state: RootState, field: string) =>
  (state.entities.field_uris && state.entities.field_uris[field]) || null;
export const pending = (state: RootState, reducerPropName: string) =>
  state.queries[reducerPropName] && state.queries[reducerPropName].isPending;
export const instanceUripending = (state: RootState, field: string) =>
  state.queries[`gget_${field}`] && state.queries[`gget_${field}`].isPending;
