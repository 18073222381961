/* eslint-disable no-confusing-arrow */
import { RootState } from 'src/reducers/rootReducer';
import { Action } from 'redux';
import { navigate } from '@reach/router';
import { getDefaultView } from 'src/app/routes/routes';
import isEqual from 'lodash/isEqual';

export interface CustomGlideRoute {
  fullPath: string;
  isHighlighted: boolean;
  tabName?: string[];
  activeView: string | null;
}
export interface TabState {
  moduleTabs: CustomGlideRoute[];
  currentPath: string;
  dashboard: boolean;
}
interface TabActionType extends Action {
  path: string;
  data: any;
}

const initialState = (): TabState => {
  return {
    moduleTabs: [],
    currentPath: '',
    dashboard: true,
  };
};

export enum TabsAction {
  OPEN_TAB = 'OPEN_TAB',
  CLOSE_TAB = 'CLOSE_TAB',
  MODIFY_TAB_NAME = 'MODIFY_TAB_NAME',
  TOGGLE_VIEW = 'TOGGLE_VIEW',
  SHOW_DASHBOARD = 'SHOW_DASHBOARD',
}

export const tabs = (state: TabState = initialState(), action: TabActionType) => {
  switch (action.type) {
    case TabsAction.OPEN_TAB: {
      const path = action.path;
      let activeViewKey: string | null = getDefaultView(path);
      const paramsString = action.path.split('?')[1];
      if (paramsString) {
        const searchParams = new URLSearchParams(paramsString);
        activeViewKey = searchParams.get('activeView');
      }
      let moduleTabsCopy = [...state.moduleTabs];
      moduleTabsCopy = moduleTabsCopy.map(tab => ({
        ...tab,
        isHighlighted: false,
      }));
      const index = moduleTabsCopy.findIndex(tab => tab.fullPath === path.split('?')[0]);
      navigate(path.split('?')[0]);
      if (index === -1) {
        const newTab = {
          fullPath: path.split('?')[0],
          isHighlighted: true,
          activeView: activeViewKey,
        };
        return {
          ...state,
          moduleTabs: [...moduleTabsCopy, newTab],
          currentPath: path,
          dashboard: false,
        };
      } else {
        moduleTabsCopy[index].isHighlighted = true;
        moduleTabsCopy[index].activeView = activeViewKey;
        return {
          ...state,
          moduleTabs: moduleTabsCopy,
          currentPath: path,
          dashboard: false,
        };
      }
    }
    case TabsAction.TOGGLE_VIEW: {
      const activeView = action.data.activeView;
      const updatedModuleTabs = state.moduleTabs.map((tab: CustomGlideRoute) =>
        tab.fullPath === action.data.fullPath ? { ...tab, activeView } : tab,
      );
      return {
        ...state,
        moduleTabs: updatedModuleTabs,
      };
    }
    case TabsAction.CLOSE_TAB: {
      const tab = action.data;
      const newTabs = [...state.moduleTabs];
      const currentTab = newTabs.find((el: CustomGlideRoute) => el.isHighlighted);
      const newPath: string = state.currentPath;
      const index = newTabs.indexOf(tab);

      newTabs.splice(index, 1);
      if (isEqual(currentTab, tab)) {
        navigate('/');
        return {
          ...state,
          moduleTabs: newTabs,
          currentPath: '/',
          dashboard: true,
        };
      }
      return {
        ...state,
        moduleTabs: newTabs,
        currentPath: newPath,
        dashboard: true,
      };
    }
    case TabsAction.MODIFY_TAB_NAME: {
      const updatedModuleTabs = state.moduleTabs.map((tab: CustomGlideRoute) =>
        tab.fullPath === action.data.fullPath ? { ...tab, tabName: action.data.tabName } : tab,
      );
      return {
        ...state,
        moduleTabs: updatedModuleTabs,
      };
    }
    case TabsAction.SHOW_DASHBOARD: {
      const newTabs = [...state.moduleTabs];
      newTabs.map(tab => (tab.isHighlighted = false));
      navigate('/');
      return {
        ...state,
        moduleTabs: newTabs,
        currentPath: '/',
        dashboard: true,
      };
    }
    default:
      return state;
  }
};

export const tabSelector = (state: RootState) => state.tabs;
