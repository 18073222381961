import { formatMultiplierXMLParser } from '@virtus/common/utils/format-multiplier-xml-parser/formatMultiplierXMLParser';
import { GlideMappedDataTypes } from 'src/components/forms/form-elements';
import { FormEntity } from 'src/components/forms/glide-data-content/glide-data-content.model';
import { ActionArguments, Argument } from 'src/models/order/glideActionArguments.api.model';
import SearchService from 'src/services/search.service';
import { getFormFieldValue } from '../common-mapper-functions';

export interface GlideFormElementTypeParams {
  dataType: string;
  fieldName: string;
  hasLookUps: boolean;
}

export const getFormElementType = ({
  dataType,
  fieldName,
  hasLookUps,
}: GlideFormElementTypeParams): GlideMappedDataTypes => {
  switch (dataType) {
    case 'bit':
      return 'checkbox';
    case 'decimal':
      return 'number';
    case 'datetime':
      return 'calendar';
    case 'largestring':
    case 'string':
      return 'text';
    case 'object': {
      if (fieldName.toLowerCase() === 'position') return 'toggler';
      if (!hasLookUps) return 'search';
      return 'select';
    }
    case 'objectcollection':
      return 'tableModal';
    default:
      console.warn(`Unhandled data type dataType ${dataType}. Defaulting to decimal`);
      return 'number';
  }
};

const getOptionColor = (itemName: string) => {
  if (itemName.toLowerCase() === 'sell') return 'var(--red)';
  if (itemName.toLowerCase() === 'buy') return 'var(--green)';
  return undefined;
};

export const getOptions = (lookups?: { [key: string]: string }) => {
  if (!lookups) return {};
  const nextLookups: any = {};
  Object.entries(lookups).forEach(([key, value]: any) => {
    nextLookups[key] = {
      name: value,
      value: key,
      color: getOptionColor(value),
    };
  });
  return nextLookups;
};

const getBooleanValue = (value: string | undefined) => Boolean(value === 'true');

const getDefaultValue = (dataType: string, elementType: string, defaultValue?: any) => {
  if (dataType === 'bit') return getBooleanValue(defaultValue as string);
  if (dataType === 'object' && elementType === 'select' && defaultValue) {
    return Object.values(defaultValue)[0];
  }
  return defaultValue || '';
};

export const compareOrder = (key1: string, key2: string, elements: { [key: string]: Argument }) => {
  if (elements[key1].ordering < elements[key2].ordering) {
    return -1;
  }
  if (elements[key1].ordering > elements[key2].ordering) {
    return 1;
  }

  return 0;
};

export const mapActionArgumentsToActionForm = (
  actionArguments: ActionArguments,
  actionDisplayView?: any,
  customActionOverride?: { [key: string]: any },
) => {
  if (!actionArguments) {
    return {
      name: '',
      formFields: {},
      actionUri: '',
    };
  }
  const useDisplayView = actionArguments?.view && actionArguments?.view === actionDisplayView?.data?.view;
  const elements = actionArguments?.arguments;
  const displayViewFDD = actionDisplayView?.display_view?.field_display_definitions;
  const sortedArgumentKeys =
    actionArguments?.view && displayViewFDD
      ? Object.keys(elements).sort((a, b) =>
          compareOrder(
            a,
            b,
            displayViewFDD.reduce((acc: any, item: any) => {
              if (item['argument']) {
                acc[item['argument']] = item;
              }
              return acc;
            }, {}),
          ),
        )
      : Object.keys(elements).sort((a, b) => compareOrder(a, b, elements));

  const filterArguments = sortedArgumentKeys.filter(
    key => key !== 'instance/argument/add_to_hypo_scenario_target_scenario',
  );

  const mappedActionEntity: FormEntity = {
    name: actionArguments?.display_name,
    actionUri: actionArguments?.action_uri,
    formFields: filterArguments.reduce((accumulatedFields, argument_uri) => {
      const fieldFromDisplayView = getFormFieldFromDisplayView(argument_uri, actionDisplayView, customActionOverride);
      const argument: Argument = actionArguments?.arguments[argument_uri];
      const formElementType = getFormElementType({
        dataType: argument.argument_type.toLowerCase(),
        fieldName: argument.display_name,
        hasLookUps: argument.hasOwnProperty('lookups'),
      });
      const dataType = argument.argument_type.toLowerCase();
      const object_type = argument.object_type_?.toLowerCase();
      const searchType = object_type ? object_type.replace('object_types/', '') : '';
      return {
        ...accumulatedFields,
        [argument_uri]: {
          name: useDisplayView ? fieldFromDisplayView?.displayName : argument.argument_name,
          displayName: useDisplayView ? fieldFromDisplayView?.displayName : argument.display_name,
          required: useDisplayView ? fieldFromDisplayView?.required : argument.argument_required,
          dataType: useDisplayView ? fieldFromDisplayView?.dataType : dataType,
          searchType: useDisplayView ? fieldFromDisplayView.searchType : searchType,
          searchService: SearchService().search,
          formElementType: useDisplayView ? fieldFromDisplayView?.formElementType : formElementType,
          readonly: false,
          defaultValue: useDisplayView
            ? fieldFromDisplayView?.defaultValue
            : getDefaultValue(dataType, formElementType, argument?.argument_default as string),
          options: useDisplayView ? fieldFromDisplayView.options : getOptions(argument?.lookups),
          formatMultiplier: argument.format_multiplier
            ? formatMultiplierXMLParser(argument.format_multiplier)
            : undefined,
          hidden: useDisplayView ? fieldFromDisplayView?.isHidden : false,
        },
      };
    }, {}),
  };

  return mappedActionEntity;
};

const getFormFieldFromDisplayView = (argument_uri: string, actionDisplayView: any, customActionOverride = {}) => {
  const fieldFromDisplayView = actionDisplayView?.display_view?.field_display_definitions.find(
    (item: any) => item?.argument === argument_uri,
  );

  if (!fieldFromDisplayView) return {};

  const instance_uri = Object.keys(fieldFromDisplayView.field)[0];
  const firstField: any = Object.values(fieldFromDisplayView.field)[0];
  const dataType = firstField.data_type.replace('lookups/', '');
  const object_type = firstField.object_type || firstField.object_type_;
  const searchType = object_type ? object_type.replace('object_types/', '') : '';
  const name = firstField.display_name;
  const style = fieldFromDisplayView.style
    ? (Object.values(fieldFromDisplayView.style)[0] as any)
    : { is_editable: true, is_hidden: false };
  const defaultValue =
    (customActionOverride as any)?.[instance_uri]?.defaultValue ||
    fieldFromDisplayView.value ||
    fieldFromDisplayView?.default_value;
  const renderType = fieldFromDisplayView.render_type ?? null;
  let format = '';
  if (firstField.hasOwnProperty('format')) {
    format = firstField.format.uri
      ? firstField.format.uri.replace('lookups/', '')
      : firstField.format.replace('lookups/', '');
  }

  return {
    name,
    displayName: name,
    dataType,
    defaultValue: getFormFieldValue({ dataType, defaultValue }),
    formElementType: getFormElementType({
      dataType,
      fieldName: firstField.display_name,
      hasLookUps: (customActionOverride as any)?.[instance_uri]?.lookups || firstField?.lookups,
    }),
    required: Boolean(firstField.required_field),
    format,
    searchType,
    searchService: SearchService().search,
    options: (customActionOverride as any)?.[instance_uri]?.lookups || getOptions(firstField.lookups),
    renderType,
    isHidden: style.is_hidden,
  };
};
