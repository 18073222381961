import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

const ChartIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ fontSize: '16px' }}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path fill={props.nativeColor || 'black'} d="M5 15H2V5h3v10zm5 0H7V1h3v14zm5 0h-3V8h3v7z" />
    </g>
  </SvgIcon>
);

export default ChartIcon;
