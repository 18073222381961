import { FormFieldsType } from 'src/components/forms/form-elements/FormElement.model';
import { RootState } from 'src/reducers/rootReducer';
import { getFieldsFromView } from 'src/mappers/common-mapper-functions';
import { ViewElement } from 'src/models/api/viewElement';
import { getDisplayViewGroup, displayViewGroupSelector, isPending, GetDisplayViewType } from '../display-view';

const getHypoScenarioDetailsFields = ({
  data,
  display_name,
  uri,
  display_view,
}: {
  data: string;
  display_name: string;
  uri: string;
  display_view: any;
}) => {
  const scenarioData = JSON.parse(data);
  const scenarioName = display_name;

  const hypoScenarioDetailsGroups: FormFieldsType = (Object.entries(scenarioData) as Array<
    [string, ViewElement[]]
  >).reduce(
    (acc, [key, value]: [string, ViewElement[]]) => ({
      ...acc,
      [isNaN(Number(key)) ? key : `Scenario group ${key}`]: getFieldsFromView(value),
    }),
    {},
  );

  return { groups: hypoScenarioDetailsGroups, name: scenarioName, uri, displayView: JSON.parse(display_view) };
};

export const get = ({ uri, fetchOptions, activeView }: GetDisplayViewType) =>
  getDisplayViewGroup({
    viewName: activeView,
    uri,
    fetch_options: fetchOptions,
    transformFn: getHypoScenarioDetailsFields,
  });

export const selector = (state: RootState) => displayViewGroupSelector(state, 'hypo_scenarios');

export const pending = (state: RootState) => isPending(state, 'hypo_scenarios');
