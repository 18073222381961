import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

export const getDashboardsData = () => {
  return {
    url: `${endpoints.gsearch.root}`,
    body: {
      object_type: 'dashboards',
      fields: ['display_name', 'enabled'],
    },
    transform: (body: any) => ({
      dashboardsData: body.filter((dashboard: any) => dashboard.data?.enabled !== false),
    }),
    force: false,
    options: { method: 'POST' },
    queryKey: 'getDashboardsData',
    update: {
      dashboardsData: (_: any, next: any) => next,
    },
  };
};

export const dashboardsData = (state: RootState) => state.entities.dashboardsData;
export const dashboardId = (state: RootState) => state.components.dashboard.dashboardId;
