import { AppConfig, createB2cConfig, GlideConfig, GlideDashboardConfig, IDPConfig } from '@virtus/common/auth';

export interface GlideAppConfig extends AppConfig {
  glide: GlideConfig;
  idp: IDPConfig;
  dashboards: GlideDashboardConfig;
  marketmapURLS?: string[];
  // Route toggling
  routePathsDisabled?: string[];
  // Feature toggling
  disableLivePrice?: boolean;
  nexusLinks?: any;
}

// TODO: Store on .env but a pipeline will need to exist to inject env values

/**
 * returns correct GlideConfig object depending on __ENVIRONMENT__
 *
 * Using "if" statements other environments should be tree-shaked by webpack and not appear in bundled js
 */
const getConfig = (): GlideAppConfig => {
  let CYPRESS_ENV;
  if (typeof Cypress !== 'undefined') {
    CYPRESS_ENV = Cypress.env('__ENVIRONMENT__');
  }

  if (CYPRESS_ENV === 'dev' || __ENVIRONMENT__ === 'dev') {
    return {
      // Temporary config for IDP testing integration
      glide: {
        baseURL: 'https://glide-dev.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://glide-dev.alphakinetic.com:8082',
        environment: 'glide-qa',
      },
      b2c: createB2cConfig({
        appName: 'Glide DEV',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideDev',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-dev',
        idleTimeout: 0,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (__ENVIRONMENT__ === 'mock') {
    return {
      glide: {
        // Uncomment next line if not running the GlideWeb API locally
        baseURL: 'https://248dc3f3-2a73-499a-9fae-29d3c12868cf.mock.pstmn.io/',
      },
      b2c: createB2cConfig({
        appName: 'Glide LOCAL',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        //TODO: need to change when IDP details are available
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideDev',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: '',
        idleTimeout: 20 * 60 * 1000,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (__ENVIRONMENT__ === 'local') {
    return {
      glide: {
        // Uncomment next line if not running the GlideWeb API locally
        // baseURL: 'https://glide.qa.alphakinetic.com:8080',
        // API_WEB_SOCKET_URL: 'wss://glide.qa.alphakinetic.com:8082',
        baseURL: 'http://localhost:8080',
        API_WEB_SOCKET_URL: 'ws://localhost:8082',
        environment: 'glide-dev',
      },
      b2c: createB2cConfig({
        appName: 'Glide LOCAL',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        //TODO: need to change when IDP details are available
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideDev',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-local',
        idleTimeout: 0,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  // TODO: The config should be populated via environment variable in CI: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72433
  if (__ENVIRONMENT__ === 'BSP') {
    return {
      glide: {
        baseURL: 'https://bsp.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://bsp.alphakinetic.com:8082',
        environment: 'bsp',
      },

      // This is a quick fix to disable some feature for clients.
      // This should be addressed properly with: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72173
      routePathsDisabled: ['dashboard', 'deals', 'credit'],

      disableLivePrice: true,

      nexusLinks: {
        compliance: 'https://nexus.virtusconnect.com/compliance',
        reporting: 'https://nexus.virtusconnect.com/reporting',
      },

      // TBC: Taufique
      idp: {
        baseURL: 'https://login.fisglobal.com/idp',
        firm: 'ben160551',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-bsp',
        serviceProviderId: 'glide-bsp',
        idleTimeout: 20 * 60 * 1000,
      },

      dashboards: {
        baseURL: 'https://apigsa.com',
      },

      // Need whitelist for EC2 BSP
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (__ENVIRONMENT__ === 'qa') {
    return {
      glide: {
        baseURL: 'https://glide.qa.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://glide.qa.alphakinetic.com:8082',
        environment: 'rcm-test',
      },
      b2c: createB2cConfig({
        appName: 'Glide QA',
        clientId: 'd5180215-4a5b-4c7f-9678-bf888118523c',
        tenantId: 'stagingvirtuswebportal',

        userLoginApiUrl: 'https://stgnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        //TODO: need to change when IDP details are available
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-qa',
        idleTimeout: 0,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (__ENVIRONMENT__ === 'demo') {
    return {
      glide: {
        baseURL: 'https://demo.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://demo.alphakinetic.com:8082',
        environment: 'demo',
      },
      b2c: createB2cConfig({
        appName: 'Glide',
        clientId: '3fc10a9a-e29c-42db-9af8-5d31407be3f3',
        tenantId: 'prodvirtuswebportal',

        userLoginApiUrl: 'https://next.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebDemo',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'demo',
        serviceProviderId: 'glide-demo',
        idleTimeout: 0,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      //marketmapURLS: ['https://www.marketmapmuse.com', 'https://www2.marketmapmuse.com'],Currently these URLs are not whitelisted hence commented
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (__ENVIRONMENT__ === 'prod') {
    return {
      glide: {
        baseURL: 'https://rcm-test.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://rcm-test.alphakinetic.com:8082',
        environment: 'rcm-test',
      },
      b2c: createB2cConfig({
        appName: 'Glide',
        clientId: '3fc10a9a-e29c-42db-9af8-5d31407be3f3',
        tenantId: 'prodvirtuswebportal',

        userLoginApiUrl: 'https://next.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        //TODO: need to change when IDP details are available
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideDev',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: '',
        idleTimeout: 20 * 60 * 1000,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      //marketmapURLS: ['https://www.marketmapmuse.com', 'https://www2.marketmapmuse.com'],Currently these URLs are not whitelisted hence commented
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (__ENVIRONMENT__ === 'commerzbank') {
    return {
      // Temporary config for IDP testing integration
      glide: {
        baseURL: 'https://commerzbank.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'https://commerzbank.alphakinetic.com:8080',
        environment: 'glide-commerzbank',
      },
      b2c: createB2cConfig({
        appName: 'Glide DEV',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        baseURL: 'https://login.fisglobal.com/idp',
        firm: 'Commerzbankprod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-commerzbank',
        serviceProviderId: 'glide-commerzbank',
        idleTimeout: 20 * 60 * 1000,
      },

      // This is a quick fix to disable some feature for clients.
      // This should be addressed properly with: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72173
      routePathsDisabled: ['dashboard', 'deals', 'credit'],

      disableLivePrice: true,
      nexusLinks: {
        compliance: 'https://nexus.virtusconnect.com/compliance',
        reporting: 'https://nexus.virtusconnect.com/reporting',
      },
      dashboards: {
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (CYPRESS_ENV === 'demo-release' || __ENVIRONMENT__ === 'demo-release') {
    return {
      // Temporary config for IDP testing integration
      glide: {
        baseURL: 'https://demo-release.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://demo-release.alphakinetic.com:8082',
        environment: 'demo-release',
      },
      b2c: createB2cConfig({
        appName: 'Glide DEV',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      }),
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebDemoRelease',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'demo-release',
        serviceProviderId: 'glide-demo-release',
        idleTimeout: 0,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  if (CYPRESS_ENV === 'albacore-test' || __ENVIRONMENT__ === 'albacore-test') {
    return {
      glide: {
        baseURL: 'https://albacore-test.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://albacore-test.alphakinetic.com:8082',
        environment: 'albacore-test',
      },
      b2c: createB2cConfig({
        appName: 'Glide',
        clientId: '3fc10a9a-e29c-42db-9af8-5d31407be3f3',
        tenantId: 'prodvirtuswebportal',
        b2cSigninPolicy: 'B2C_1_SignUpSignIn',
        userLoginApiUrl: 'https://next.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
        b2cPasswordResetPolicy: 'B2C_1_PasswordReset',
        b2cProfileEditPolicy: 'B2C_1_ProfileEditing',
      }),
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'fid185768uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore-test',
        serviceProviderId: 'glide-albacore-test',
        idleTimeout: 0,
      },
      dashboards: {
        //TODO: need to change when API is available
        baseURL: 'https://apigsa.com/dashboard/api',
      },
      //marketmapURLS: ['https://www.marketmapmuse.com', 'https://www2.marketmapmuse.com'],Currently these URLs are not whitelisted hence commented
      marketmapURLS: ['https://tbd3.marketmapmuse.com', 'https://tbd2.marketmapmuse.com'],
    };
  }

  throw new Error(`No config for __ENVIRONMENT__: '${__ENVIRONMENT__}'`);
};

const config = getConfig();
export default config;
