import { Button, Dialog } from '@material-ui/core';
import { ArrowLeft, Close, FullscreenExit, Help } from '@material-ui/icons';
import React from 'react';
import { DangerButton, PrimaryButton, TertiaryButton } from 'src/Buttons';
import styled from 'styled-components';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { colors } from 'src/theme';
import ExpandIcon from 'src/icons/ExpandIcon';
const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const HelpIcon = styled(Help)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: #fff;
`;

const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: #fff;
`;

const IconButton = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: #fff;
`;

const StyledDialog = styled.div`
  position: relative;
  width: auto;
  height: auto;
  background: #1b252f;
  color: #fff;
  overflow: auto;
`;

const ButtonSpacing = styled.div`
  width: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background: #293a49;
  padding-left: 20px;
  padding-right: 10px;
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #28333c;
  display: flex;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const IconBtn = styled(IconButton)`
  color: ${colors.bgLight};
  cursor: pointer;
  padding: 5px;
  width: 36px;
  height: 34px;
`;

const IconButtonStyled = styled(IconButton)`
  padding: 0;
  color: var(--text);
  width: 36px;
  height: 34px;
  &[disabled] {
    color: #8c8c8ca1;
  }
`;

const Title = styled.h4<{ isDraggable: boolean | undefined }>`
  cursor: ${props => (props.isDraggable ? 'move' : `default`)};
`;

interface ModalButton {
  text: string;
  action: (e: any) => void;
  disabled?: boolean;
}

export interface ModalProps {
  title?: string;
  helpText?: string;
  onClose: () => void;
  onHelp?: () => void;
  children: React.ReactChild;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  fullWidth?: boolean;
  show: boolean;
  headerStyle?: any;
  footerStyle?: any;
  primaryButton?: ModalButton;
  secondaryButton?: ModalButton;
  dangerButton?: ModalButton;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  dataTestId?: boolean;
  ariaLabelledBy?: string;
  isDraggable?: boolean;
  disableBackdropClick?: boolean;
  dialogStyle?: any;
  showBackButton?: boolean;
  isFullScreen?: boolean;
  onExpand?: () => void;
  backButtonCallback?: () => void;
}

const Modal = ({
  title,
  helpText,
  onHelp = () => alert('You hit help'),
  onClose,
  children,
  show,
  primaryButton,
  secondaryButton,
  dangerButton,
  footer,
  header,
  headerStyle,
  footerStyle,
  maxWidth,
  fullWidth,
  hideHeader,
  hideFooter,
  dataTestId,
  ariaLabelledBy,
  isDraggable,
  disableBackdropClick = false,
  dialogStyle,
  showBackButton = false,
  backButtonCallback,
  isFullScreen,
  onExpand,
}: ModalProps) => {
  return (
    <Dialog
      data-testid={dataTestId || 'modal'}
      open={show}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby={ariaLabelledBy || 'create-portfolio-group'}
      PaperComponent={isDraggable ? PaperComponent : undefined}
      disableBackdropClick={disableBackdropClick}
      fullScreen={isFullScreen}
    >
      <StyledDialog style={dialogStyle}>
        {!hideHeader && (
          <Header data-testid="modal-header" style={headerStyle}>
            {header || (
              <>
                {showBackButton && (
                  <IconBtn onClick={backButtonCallback} data-testid="back-button" color="inherit">
                    <ArrowLeft />
                  </IconBtn>
                )}
                <Title data-testid="dialog-title" id="draggable-dialog-title" isDraggable={isDraggable}>
                  {title}
                </Title>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {helpText && (
                    <IconButton data-testid="modal-help" onClick={onHelp}>
                      <HelpIcon />
                    </IconButton>
                  )}
                  {onExpand && (
                    <IconButtonStyled onClick={onExpand} color="inherit">
                      {(isFullScreen && <FullscreenExit />) || <ExpandIcon fillColor="var(--text)" />}
                    </IconButtonStyled>
                  )}
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </>
            )}
          </Header>
        )}

        {children}
        {!hideFooter && (
          <Footer data-testid="modal-footer" style={footerStyle}>
            {footer || (
              <>
                {secondaryButton && (
                  <TertiaryButton onClick={secondaryButton.action}>{secondaryButton.text}</TertiaryButton>
                )}
                <ButtonSpacing />
                {primaryButton && (
                  <PrimaryButton onClick={primaryButton.action} disabled={primaryButton.disabled}>
                    {primaryButton.text}
                  </PrimaryButton>
                )}
                {dangerButton && (
                  <DangerButton onClick={dangerButton.action} disabled={dangerButton.disabled}>
                    {dangerButton.text}
                  </DangerButton>
                )}
              </>
            )}
          </Footer>
        )}
      </StyledDialog>
    </Dialog>
  );
};

export default Modal;
