import { endpoints } from 'src/api/constants';
import capitalize from 'lodash/capitalize';
import { parseWorkflowTransitions } from 'src/mappers/common-mapper-functions';

export interface getDisplayViewGroupProps {
  viewName: string;
  uri: string;
  fetch_options?: string;
  transformFn?: any;
  expand_prop?: string;
}

export type GetDisplayViewType = { uri: string; activeView: string; fetchOptions?: string };

const defaultDealsDisplayView = null;

export const parseDisplayViewData = (body: any) => ({
  data: {
    ...(body.data ? JSON.parse(body.data) : {}),
  },
  fieldRules: body.field_rules ? body.field_rules : null,
  uri: body.uri,
  displayName: body.display_name,
  displayView: JSON.parse(body.display_view),
  displayViewData: body.display_view ? JSON.parse(body.display_view).data : {},
  workflowActions: body?.workflow_transitions ? parseWorkflowTransitions(JSON.parse(body.workflow_transitions)) : [],
});

export const getDisplayViewGroup = ({
  viewName,
  uri,
  fetch_options,
  transformFn,
  expand_prop = 'actions',
}: getDisplayViewGroupProps) => ({
  url: endpoints.displayView.groups,
  transform: (body: any) => ({
    views: {
      [`clientData_${viewName}`]: { inspectorData: transformFn ? transformFn(body) : parseDisplayViewData(body) },
    },
  }),
  options: { method: 'GET' },
  body: { uri, fetch_options, expand_prop },
  queryKey: `get${capitalize(viewName)}DisplayView`,
  force: true,
  update: {
    views: (prev: any, next: any) => ({
      ...prev,
      [`clientData_${viewName}`]: {
        ...prev[`clientData_${viewName}`],
        inspectorData: next[`clientData_${viewName}`].inspectorData,
      },
    }),
  },
});

export const displayViewGroupSelector = (state: any, viewName: string) =>
  state.entities.views?.[`clientData_${viewName}`]?.inspectorData || defaultDealsDisplayView;

export const isPending = (state: any, viewName: string) => {
  const queryKey = `get${capitalize(viewName)}DisplayView`;
  return state.queries[queryKey] && state.queries[queryKey].isPending;
};

export const getDisplayView = (uri: string) => ({
  url: endpoints.displayView.root,
  transform: (body: any) => ({
    displayViewData: {
      data: {
        ...(body.data ? JSON.parse(body.data) : {}),
      },
      display_view: body.display_view ? body.display_view : {},
      fieldRules: body.field_rules ? body.field_rules : null,
    },
  }),
  options: { method: 'GET' },
  body: { uri },
  queryKey: `getDisplayView`,
  force: true,
  update: {
    displayViewData: (prev: any, next: any) => ({ [uri]: next, ...prev }),
  },
});

export const displayViewSelector = (state: any) => state.entities.displayViewData || defaultDealsDisplayView;

export const isDVPending = (state: any) => state.queries.getDisplayView && state.queries.getDisplayView.isPending;
