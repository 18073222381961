export interface IPortfolioState {
  selectedFundURIs: any;
}

export const initialState: IPortfolioState = {
  selectedFundURIs: {
    portfolio_positions: [{ uri: 'EMPTY_GRID' }],
    portfolio_analyzer: [{ uri: 'EMPTY_GRID' }],
  },
};

export enum PortfolioActionTypes {
  STORE_SELECTED_PORTFOLIOS = 'STORE_SELECTED_PORTFOLIOS',
  RESET_STORED_PORTFOLIO = 'RESET_STORED_PORTFOLIO',
}

export const portfolios = (
  state: IPortfolioState = initialState,
  action: { type: PortfolioActionTypes; payload?: any },
) => {
  switch (action.type) {
    case PortfolioActionTypes.STORE_SELECTED_PORTFOLIOS:
      return {
        ...state,
        selectedFundURIs: {
          ...state.selectedFundURIs,
          [action.payload.type]: action.payload.selectedFundURIs,
        },
      };
    case PortfolioActionTypes.RESET_STORED_PORTFOLIO:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
