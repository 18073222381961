import React, { useEffect } from 'react';
import * as S from './tab-panel-navigation.style';
import * as T from 'src/components/typo/typo';
import { RootState } from 'src/reducers';
import { connect } from 'react-redux';
import { tabSelector, TabsAction, CustomGlideRoute, TabState } from 'src/reducers/tabs';
import { Dispatch } from 'redux';
import { getRoute } from 'src/app/routes/routes';
import ChartIcon from '@virtus/components/icons/ChartIcon';
interface ReduxProps {
  tabs: TabState;
}

interface ReduxDispatch {
  dispatchOpenNewTab: (path: string) => void;
  dispatchCloseTab: (data: CustomGlideRoute) => void;
  showDashboard: () => void;
}

const TabPanelNavigation: React.FC<ReduxProps & ReduxDispatch> = React.memo(
  ({ tabs, dispatchOpenNewTab, dispatchCloseTab, showDashboard }) => {
    const closeTabHandler = (tab: CustomGlideRoute, e: any) => {
      e.stopPropagation();
      dispatchCloseTab(tab);
    };

    const routeChangeCallback = (path: string) => {
      dispatchOpenNewTab(path);
    };

    useEffect(() => {
      if (getRoute(location.pathname)) {
        routeChangeCallback(location.pathname);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getToolTipFromArray = (tabNamesArray: any) => {
      let toolTipString = '';
      tabNamesArray.forEach(
        (names: string) =>
          (toolTipString = `${toolTipString}${names}
        `), //Adding a new Line after each tab Name in case of multiple tabName
      );

      return toolTipString;
    };

    const renderTabs = (tabs: CustomGlideRoute[]) => {
      return tabs.map((tab: CustomGlideRoute, i: number) => {
        const matchedRoute = getRoute(tab.fullPath);
        const routeTabName = matchedRoute.displayName ?? matchedRoute.name;
        const tabName = tabs[i].tabName && Array.isArray(tabs[i].tabName) ? tabs[i].tabName![0] : routeTabName;
        const tabNameCount = Array.isArray(tabs[i].tabName) ? tabs[i].tabName!.length : 0;
        const toolTipTitle =
          Array.isArray(tabs[i].tabName) && tabs[i].tabName!.length > 1
            ? getToolTipFromArray(tabs[i].tabName)
            : tabName;
        return (
          <S.StyledTooltip key={i} title={toolTipTitle}>
            <S.Tab
              highlighted={tab.isHighlighted}
              onClick={routeChangeCallback.bind(event, tab.fullPath)}
              data-testid={tabName}
            >
              <T.TitleWithEllipsis style={{ maxWidth: '180px' }}>{tabName}</T.TitleWithEllipsis>
              {tabNameCount > 1 && <span style={{ paddingLeft: '5px' }}>{`+ ${tabNameCount - 1}`}</span>}
              <i
                className="dx-icon dx-icon-close"
                data-testid={`${tabName}-close`}
                onClick={(e: any) => closeTabHandler(tab, e)}
              />
            </S.Tab>
          </S.StyledTooltip>
        );
      });
    };

    return (
      <S.GlideHeader>
        <>
          <S.DashBoardTab key="dashboard" active={tabs.dashboard} onClick={showDashboard} data-testid="dashboard">
            <ChartIcon nativeColor="white" style={{ fontSize: '14px' }} />
          </S.DashBoardTab>
          {renderTabs(tabs.moduleTabs)}
        </>
      </S.GlideHeader>
    );
  },
);

const mapStateToProps = (state: RootState): ReduxProps => ({
  tabs: tabSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  dispatchOpenNewTab: (path: string) => dispatch({ type: TabsAction.OPEN_TAB, path }),
  dispatchCloseTab: (data: CustomGlideRoute) => dispatch({ type: TabsAction.CLOSE_TAB, data }),
  showDashboard: () => dispatch({ type: TabsAction.SHOW_DASHBOARD }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabPanelNavigation);
