import { updateEntities } from 'redux-query';
import { GlideObject } from 'src/models/glide/glideObject';
import { store } from 'src/app/store';
import config from 'src/config';
import { GlideNotificationType, NotificationsAction } from 'src/reducers/notifications';
import { GlideSession } from '@virtus/common/auth/reducer';

interface ActionDisplayView {
  actions: string[];
  instance: string; // same as resolved_entity_uri
  view: string;
}

export interface ActionResult {
  resolved_entity_uri: string;
  resolved_entity: GlideObject;
  display_view: ActionDisplayView;
  date: string;
  resolved_entity_type: GlideNotificationType;
  error?: string;
  data?: any;
}

// TODO: Create a static WS connection (initialised by the app) that is
// listening for completed Glide event payloads to be synced
// https://github.com/AlphaKinetic/Glide/issues/2410

// Factory to handle glide web socket action request:
// Use the web socket connection to resolve action and update reducer
// Params are those expected by the glide action arguments of the service
const webSocketClient = (ws: WebSocket, isActionMutating?: boolean) => {
  ws.onmessage = (event: any): any => {
    const actionResult: ActionResult = JSON.parse(event.data);
    if (actionResult.error) {
      // VERACODE restricted output
      // console.error('actionResult.error', actionResult.error);
      return store.dispatch({
        type: NotificationsAction.ERROR_NOTIFICATION,
        payload: {
          errorMessage: actionResult.error,
        },
      });
    }
    if (isActionMutating) {
      store.dispatch({
        type: NotificationsAction.MUTATE_SUCCESS,
        payload: { title: 'Update Complete' },
      });
    } else {
      store.dispatch({
        type: NotificationsAction.ACTION_RESOLVED_NOTIFICATION,
        payload: actionResult,
      });
    }
    store.dispatch(
      updateEntities({
        // Last action result for notification
        actionResult: () => actionResult,
        // All action results (required for handling new entities display_view)
        // Not currently used but will be
        actionResults: (actionResults: ActionResult[]) => ({
          [actionResult.resolved_entity_uri]: actionResult,
          ...actionResults,
        }),
      }),
    );

    // Action resolve on the first event except when processing a scenario run
    // if (actionResult.resolved_entity_type !== GlideNotificationTypes.hypo_scenarios_run_processing) {
    //   ws.close(1000);
    // }
  };

  return {
    onopen: ws.onopen,
    send: (args: object) => {
      // Notify via same reducer as Redux query
      store.dispatch({ type: NotificationsAction.PENDING_NOTIFICATION });
      // WS proto needs to stringify arguments
      ws.send(JSON.stringify(args));
    },

    close: () => ws.close(),
  };
};

// Returns a new web socket connection so requests are not queued on the same connection
export const actionResolver = (isActionMutating?: boolean) =>
  new Promise((resolve, reject) => {
    if (!config.glide.API_WEB_SOCKET_URL) throw ReferenceError('Missing API_WSS_URL');
    try {
      const ws = new WebSocket(config.glide.API_WEB_SOCKET_URL);
      ws.onopen = () => {
        const wsClient = webSocketClient(ws, isActionMutating);
        resolve(wsClient);
      };
    } catch (e) {
      reject(Error(`Failed to created web socket connection ${e}`));
    }
  });

export interface IExecuteActionProps {
  action: any;
  target_uri: any;
  glideSession?: GlideSession;
}

export const executeAction = ({ action, target_uri, glideSession }: IExecuteActionProps) => {
  actionResolver().then((wsClient: any) =>
    wsClient.send({
      target_uri,
      action_uri: action.uri,
      arguments: action?.arguments || null,
      glide_token: glideSession?.token,
      environment: glideSession?.environment,
      date: action.accepts_from_date ? action.date : null,
    }),
  );
};

export interface IGetActionsCollection {
  data: any;
  resolveAction: any;
}

export interface IAction {
  text: string;
  onClick: () => void;
  testId: string;
}

export interface IActionCollections {
  [key: string]: {
    actions: {
      text: string;
      onClick: () => void;
      testId?: string;
    }[];
    searchOption?: {
      isSearchable: boolean;
      filter?: string;
      isSearchDisabled?: boolean;
    };
    templateName?: string;
  };
}
export const getStaticActionsFromView = ({ data, resolveAction }: IGetActionsCollection): IAction[] => {
  return data?.clientViewConfiguration?.actions_collection?.map((action: any) => {
    return {
      text: action.display_name,
      onClick: () => resolveAction(action.uri),
      testId: `${action.uri.lastSplitValue()}_action`,
    };
  });
};
