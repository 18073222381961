import { actionTypes, requestAsync } from 'redux-query';
import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/actions';
import {
  GlideNotificationTypes,
  NOTIF_ACTION,
  NotificationsAction,
  ReduxQueryActionType,
} from 'src/reducers/notifications';
import { ActionResult } from 'src/utils/action-resolver';
import { store } from 'src/app/store';
import { HypoScenarios, ViewBlotter } from 'src/api/queries';
import { CustomGlideRoute, tabSelector } from 'src/reducers/tabs';

interface ActionResolved {
  payload: ActionResult;
}

export function* mutateStart(action: any) {
  if (action?.meta?.notification && action.meta.notification.hasOwnProperty(actionTypes.MUTATE_START)) {
    yield put({
      type: NotificationsAction.MUTATE_START,
      payload: { title: action.meta.notification[actionTypes.MUTATE_START] } || 'Saving...',
    });
  }
}

export function* mutateSuccess(action: any) {
  const tabs = yield select(tabSelector);
  const tabsCurrentPath = tabs?.currentPath?.split('?')[0];
  const viewName = tabs?.moduleTabs.find((tab: CustomGlideRoute) => tab.fullPath === tabsCurrentPath)?.activeView;
  if (action?.meta?.notification && action.meta.notification.hasOwnProperty(actionTypes.MUTATE_SUCCESS)) {
    yield put({
      type: NotificationsAction.MUTATE_SUCCESS,
      payload: { title: action.meta.notification[actionTypes.MUTATE_SUCCESS] } || 'Saved',
    });
    if (viewName && viewName !== 'hypo_scenarios')
      yield putResolve(requestAsync(ViewBlotter.getClientView(`instance/client_view_configuration/${viewName}`)));
  }
}

export function* requestFailure(action: any) {
  yield put({
    type: NotificationsAction.ERROR_NOTIFICATION,
    payload: { errorMessage: action.responseBody?.error_message },
  });
}

// Generate notification as an action of GlideNotificationTypes
export function* actionResolved(action: ActionResolved | any) {
  const glideNotificationType = GlideNotificationTypes[action?.payload?.resolved_entity_type as GlideNotificationTypes];

  if (!glideNotificationType) {
    yield put({
      type: NOTIF_ACTION(GlideNotificationTypes.action_resolved),
      payload: action.payload,
    });
  } else {
    yield put({
      type: NOTIF_ACTION(glideNotificationType),
      payload: action.payload,
      dispatch: store && store.dispatch,
    });
  }

  if (GlideNotificationTypes.hypo_scenarios_run_complete) {
    yield put(requestAsync(HypoScenarios.get()));
  }
}

export function* clearNotifications() {
  yield put({
    type: actions.errors.RESET,
  });
}

function* watchClearNotification() {
  yield takeLatest(actions.notifications.RESET, clearNotifications);
}

function* watchRequestStart() {
  yield takeLatest(ReduxQueryActionType.MUTATE_START, mutateStart);
}

function* watchRequestSuccess() {
  yield takeLatest(ReduxQueryActionType.MUTATE_SUCCESS, mutateSuccess);
}

function* watchRequestFailure() {
  yield takeLatest([ReduxQueryActionType.REQUEST_FAILURE, ReduxQueryActionType.MUTATE_FAILURE], requestFailure);
}

function* watchActionResolved() {
  yield takeLatest(NotificationsAction.ACTION_RESOLVED_NOTIFICATION, actionResolved);
}

export default [
  watchClearNotification,
  watchRequestStart,
  watchRequestSuccess,
  watchRequestFailure,
  watchActionResolved,
];
