import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';

const generateClassName = createGenerateClassName();
// TODO: Fix the style issues created by uncommenting the following line (BUG 33633)
// const generateClassName = (rule: any, styleSheet: any) => `${styleSheet.options.classNamePrefix}-${rule.key}`;

const jss = create({
  ...jssPreset(),
  // jss-insertion-point tag defined in index.html
  insertionPoint: document.getElementById('jss-insertion-point') || undefined,
});

export interface JssProps {
  children: React.ReactNode;
}

/**
 * Fix CSS injection order between JSS (Material UI) and our styled components.
 *
 * Styled components loaded 2nd so always overrides styles. Hence don't need the "&&" fix mentioned in this link:
 * https://material-ui.com/customization/css-in-js/#css-injection-order
 */
const JssOrder: React.SFC<JssProps> = ({ children }) => (
  <JssProvider jss={jss} generateClassName={generateClassName}>
    {children}
  </JssProvider>
);

export default JssOrder;
