import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const TableIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ fontSize: '16px' }}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path fill={props.nativeColor || 'black'} d="M15 15v-3H1v3h14zM1 5h14V2H1v3zm14 5V7H1v3h14z" />
    </g>
  </SvgIcon>
);

export default TableIcon;
