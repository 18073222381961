import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

export const get = (uris: string[]) => {
  return {
    url: `${endpoints.dataGrid.root}`,
    transform: (body: any) => ({ searchElement: JSON.parse(body.data)[0] }),
    body: {
      uris: `['${uris}']`,
    },
    options: { method: 'GET' },
    update: {
      searchElement: (_: any, next: any) => next,
    },
  };
};

export const selector = (state: RootState) => state.entities.searchElement;
