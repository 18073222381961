import idpAuth from '@virtus/common/auth/sagas/IDPLogin';
import glideAuthSagas from '@virtus/common/auth/sagas/glideLogin';
import { runSagas } from '@virtus/common/sagas/runSagas';
import watchGetInstrumentBehaviourActions from './instrument-behaviour-actions/instrument-behaviour-actions.saga';
import notifications from './notifications/notifications.saga';
import portfolios from './portfolio/portfolio.saga';
import orders from './orders/orders.saga';
import credit from './credit/credit.saga';
import legalEntities from './legal-entities/legal-entities.saga';
import action from './action/action.saga';

/**
 * add all sagas here
 */
const sagas = [
  ...glideAuthSagas,
  ...notifications,
  ...action,
  ...watchGetInstrumentBehaviourActions,
  ...portfolios,
  ...orders,
  ...credit,
  ...legalEntities,
  ...idpAuth,
];

/**
 * Call this from createStore to run all sagas as their own root.
 *
 * Will Restart each saga if it catches an error and console.error it
 * This stops one error bubbling to parent and killing all sagas
 *
 * @param sagaMiddleware pass in from redux init
 */
export const runAllSagas = (sagaMiddleware: any) => {
  runSagas(sagaMiddleware, sagas);
};
