import { auth, AuthActions, AuthState } from '@virtus/common/auth/reducer';
import { combineReducers } from 'redux';
import { entitiesReducer as entities, queriesReducer as queries } from 'redux-query';
import { components } from 'src/reducers/components';
// Duplicate redux-query reducer to handle custom reset
import { errors } from 'src/reducers/errors';
import { notifications } from 'src/reducers/notifications';
import { portfolios } from 'src/pages/Portfolio/views/portfolio.reducer';
import { tabs } from 'src/reducers/tabs';
import { inspectors } from 'src/reducers/inspectors';
import { formData } from 'src/reducers/forms';
import { actionFormOverride } from 'src/reducers/actions';
/**
 * Define all actions
 */
export type Actions = AuthActions;

/**
 * Define the whole State
 */
export type RootState = {
  auth: AuthState;
  entities: any;
  queries: any;
  errors: any;
  components: any;
  notifications: any;
  portfolios: any;
  tabs: any;
  inspectors: any;
  formData: any;
  actionFormOverride: any;
};

/**
 * define Root Reducer
 */
export const rootReducer = combineReducers<RootState>({
  // Common auth
  auth,
  // Redux query
  entities,
  queries,
  errors,
  // Glide
  components,
  notifications,
  portfolios,
  tabs,
  inspectors,
  formData,
  actionFormOverride,
});

export default rootReducer;
