import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const Reports: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        fill="#FFF"
        d="M10 2h2.998C13.544 2 14 2.45 14 3.007v10.986C14 14.55 13.551 15 12.998 15H3.002A1.01 1.01 0 0 1 2 13.993V3.007C2 2.45 2.449 2 3.002 2H6a2 2 0 1 1 4 0zM6 8.047H4v4.967h2V8.047zm6 .994h-2v3.973h2V9.04zM9 5.068H7v7.946h2V5.068zM8 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </g>
  </SvgIcon>
);

export default Reports;
