import { IDPConfig } from 'src/auth/index';

let idpConfig: any;

export const initIDPLogin = (config: IDPConfig) => {
  idpConfig = config;
};

export const loginRedirect = () => {
  const idpURL = createRedirectUrl(
    idpConfig.baseURL,
    idpConfig.firm,
    idpConfig.responseType,
    idpConfig.clientId,
    idpConfig.scope,
  );
  window.location.href = idpURL;
};

export const logoutRedirect = () => {
  const logOutURL = createLogoutUrl(idpConfig.baseURL, idpConfig.firm, idpConfig.clientId);
  window.location.href = logOutURL;
};

// create the redirect URL in IDP understandable format
const createRedirectUrl = (baseURL: string, firm: string, responseType: string, clientId: string, scope: string) => {
  return `${baseURL}/${firm}?response_type=${responseType}&client_id=${clientId}&scope=${scope}`;
};

// create the logout URL in IDP understandable format
const createLogoutUrl = (baseURL: string, firm: string, clientId: string) => {
  return `${baseURL}/${firm}/globalLogout.html?client_id=${clientId}&t=${Date.now()}`;
};
