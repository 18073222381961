import { requestAsync } from 'redux-query';
import { putResolve, select, takeLatest, call } from 'redux-saga/effects';
import { ActionArguments, DisplayViewApi, Glide, PortfolioBlotter } from 'src/api/queries';
import { actions, ACTION_FORM_OVERRIDE_ACTIONS } from 'src/reducers/actions';
import { ComponentActionTypes } from 'src/reducers/components';
import { executeAction } from 'src/utils/action-resolver';
import { GlideSession } from '@virtus/common/auth/reducer';
import { sortBy } from 'lodash';

interface ActionPayload {
  payload: { actionUri: string };
}

interface ExecuteActionType {
  payload: {
    arguments: any;
    targetUri: string;
    actionUri: string;
    glideSession: GlideSession;
  };
}

export function* getActionArguments(actionUri: string) {
  let data: any;
  const actionArguments = yield select(ActionArguments.selector);
  if (!actionArguments || !actionArguments.hasOwnProperty(actionUri)) {
    data = yield putResolve(requestAsync(ActionArguments.get(actionUri)));
  }

  if (data?.body?.view) {
    yield putResolve(requestAsync(DisplayViewApi.getDisplayView(actionUri)));
  }
}

export function* actionResolve(action: ActionPayload): any {
  const actionUri = action.payload?.actionUri;

  if (actionUri === 'instance/actions/fund_modeling') {
    const portfolios = yield select(Glide.selector, 'portfolios');
    const selectedFunds = yield select(PortfolioBlotter.selectedFunds, 'portfolio_positions');

    const payload = {
      'fields/modeling_portfolio': {
        lookups: selectedFunds.reduce((acc: any, item: { display_name: string; uri: string }) => {
          if (!acc[item.uri]) {
            acc[item.uri] = { name: item.display_name, value: item.uri };
          }
          return acc;
        }, {}),
        defaultValue: selectedFunds?.length === 1 && selectedFunds[0]?.uri,
      },
      'fields/model_id': {
        lookups: sortBy(portfolios, 'data.display_name').reduce((acc, item) => {
          if (!acc[item.uri]) {
            acc[item.uri] = { name: item?.data?.display_name, value: item.uri };
          }
          return acc;
        }, {}),
      },
      primaryActionButton: {
        label: 'Run',
      },
    };

    yield putResolve({
      type: ACTION_FORM_OVERRIDE_ACTIONS.SET_ACTION_OVERIDE_OBJECT,
      payload: { [actionUri]: payload },
    });
  }

  yield call(getActionArguments, actionUri);

  yield putResolve({
    type: ComponentActionTypes.TOGGLE_DISPLAY_COMPONENT,
    payload: { component: 'actionForm', props: { visible: true, actionUri } },
    meta: { source: 'SAGA_ACTION' },
  });
}

export function* executeActionWithArgument(action: ExecuteActionType) {
  const { targetUri, arguments: actionArguments, actionUri, glideSession } = action.payload;
  let target_uri = targetUri;
  // It is special case where we need to set target uri with selected model fund uri.
  if (actionUri === 'instance/actions/fund_modeling') {
    target_uri = (actionArguments as any)['instance/argument/modelid'];

    // TODO: As of now setting modelType to 'F'. Remove this once core api is accepting uri reference instead of abbrevations.
    if ((actionArguments as any)['instance/argument/modeltype']) {
      (actionArguments as any)['instance/argument/modeltype'] = 'F';
    }
  }
  yield call(
    executeAction({ action: { uri: actionUri, arguments: actionArguments }, target_uri, glideSession }) as any,
  );
}

function* watchActionResolve() {
  yield takeLatest<any>(actions.action.DISPATCH_ACTION, actionResolve);
}

function* watchExecuteAction() {
  yield takeLatest<any>(actions.action.EXECUTE_ACTION, executeActionWithArgument);
}

export default [watchActionResolve, watchExecuteAction];
